import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import SustainableBrandedMerchandiseLP from "../social-supermarket/components/landing-page/new/pages/SustainableBrandedMerchandiseLP"

const Container = styled.div``

interface Props {}

const SustainableBrandedMerchandise: FC<Props> = () => {
  return (
    <Layout fullWidth disableHeadroom>
      <SEO
        title="Sustainable Corporate Branded merchandise"
        description="Impact-led sustainable corporate branded merchandise from brands that reflect your values."
      />
      <SustainableBrandedMerchandiseLP />
    </Layout>
  )
}

export default SustainableBrandedMerchandise
