import * as React from "react"
import { FC, useEffect, useState } from "react"
import styled from "styled-components"
import { IconSellingPointType } from "../components/selling-points/LPIconSellingPointsBlock"
import LPParallaxBlock from "../components/general/LPParallaxBlock"
import LPReviewsAndTrustBlock from "../components/reviews/LPReviewsAndTrustBlock"
import LPEnquiryFormCTABlock from "../components/cta/LPEnquiryFormCTABlock"
import { colors } from "@social-supermarket/social-supermarket-model"
import LPLeadMagnetModal from "../components/lead-capture/lead-magnet/LPLeadMagnetModal"
import LPImageFeatureBlock from "../components/general/LPImageFeatureBlock"
import LPProductCarouselFullWidthBlock from "../components/products/LPProductCarouselFullWidthBlock"
import LPCorporateQuotesBlock from "../components/reviews/LPCorporateQuotesBlock"
import EnquiryFormModal from "../components/lead-capture/enquiry-form/EnquiryFormModal"
import LPBrochureCTABlock from "../components/cta/LPBrochureCTABlock"
import LPBanner6 from "../components/banner/LPBanner6"
import LPReviewsRatingBlock from "../components/reviews/LPReviewsRatingBlock"

const Container = styled.div``
const Rouge = styled.span``
const HighlightText = styled.span`
  color: ${colors.primaryLight};
  font-weight: bold;
`

const mastheadListItems = [
  "The UK's largest marketplace for sustainable, impact-led products",
  "Same-day response tailored to your brief & budget",
  "Automated impact reporting",
]

const uspItems: IconSellingPointType[] = [
  {
    icon: "tss-curated",
    // icon: "mail",
    description: (
      <div>
        Each supplier meets <HighlightText>stringent quality and impact criteria</HighlightText>,
        verified by us and third party{" "}
        <a href="https://www.goodmarket.global/minimum-standards" target="_blank">
          Good Market
        </a>
      </div>
    ),
  },
  {
    icon: "tss-storytelling",
    description: (
      <div>
        <HighlightText>Impact reporting</HighlightText> and storytelling helps you engage all
        stakeholders
      </div>
    ),
  },
  {
    icon: "tss-eco-conscious",
    description: (
      <div>
        We operate a <HighlightText>green supply chain</HighlightText> with a carbon neutral
        warehouse, recycled packaging, vegetable inks, and carbon neutral delivery.
      </div>
    ),
  },
]

interface Props {}

const SustainableBrandedMerchandiseLP: FC<Props> = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showLeadCaptureModal, setShowLeadCaptureModal] = useState<boolean>(false)

  useEffect(() => {
    // @ts-ignore
    if (typeof window !== "undefined" && window.HubSpotConversations?.widget) {
      // @ts-ignore
      window.HubSpotConversations.widget.load()
    }

    return () => {
      // @ts-ignore
      if (typeof window !== "undefined" && window.HubSpotConversations?.widget) {
        // @ts-ignore
        window.HubSpotConversations.widget.remove()
      }
    }
  }, [])

  return (
    <div>
      <LPBanner6
        title={"Corporate merch that reflects your values"}
        checklistItems={mastheadListItems}
        actionLabel={"Get In Touch"}
        onAction={() => setShowModal(true)}
        onSecondaryAction={() => setShowLeadCaptureModal(true)}
        secondaryActionLabel={"View Brochure"}
      />
      {/*<LPBanner3*/}
      {/*  backgroundColor={"#fcf7eb"}*/}
      {/*  heading="Corporate merch that reflects your values"*/}
      {/*  action="Get In Touch"*/}
      {/*  onAction={() => setShowModal(true)}*/}
      {/*  secondaryAction={"View Brochure"}*/}
      {/*  onSecondaryAction={() => setShowLeadCaptureModal(true)}*/}
      {/*  listItems={mastheadListItems}*/}
      {/*/>*/}
      {/*<LPBanner4*/}
      {/*  backgroundColor={"#f4eee0"}*/}
      {/*  heading="Corporate merch that reflects your values"*/}
      {/*  action="Get In Touch"*/}
      {/*  onAction={() => setShowModal(true)}*/}
      {/*  secondaryAction={"View Brochure"}*/}
      {/*  onSecondaryAction={() => setShowLeadCaptureModal(true)}*/}
      {/*  listItems={mastheadListItems}*/}
      {/*  imageUrl={*/}
      {/*    "https://data.socialsupermarket.org/wp-content/uploads/2018/10/The-Social-Supermarket-Branded-Merch-4-landscape-2-scaled.jpg"*/}
      {/*  }*/}
      {/*/>*/}
      <LPProductCarouselFullWidthBlock
        collectionName={"Branded Merchandise Landing Page"}
        hidePrice
        hideBrand
        showImpactArrow
      />
      <LPReviewsRatingBlock />
      <LPImageFeatureBlock
        title={<>Create ripples of change with every purchase</>}
        description={
          "We are a one-stop shop with +200 brands tackling human and environmental issues, empowering you to make a real difference every time you shop."
        }
        imageUrl={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/The-Social-Supermarket-Branded-Merch-10-square-1-scaled.jpg"
        }
        colorWay={0}
      />
      <LPImageFeatureBlock
        title={<>Verified suppliers</>}
        description={
          <div>
            <p>
              Each supplier meets stringent quality and impact criteria, verified by us and third
              parties such as{" "}
              <a href="https://www.goodmarket.global/minimum-standards" target="_blank">
                Good Market
              </a>
            </p>
            <p>
              Social Supermarket also runs a <HighlightText>green supply chain</HighlightText> with
              a carbon neutral warehouse, recycled packaging, vegetable inks, and carbon neutral
              delivery.
            </p>
          </div>
        }
        imageUrl={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/The-Social-Supermarket-Branded-Merch-1-square-scaled.jpg"
        }
        invert
        colorWay={1}
      />
      <LPImageFeatureBlock
        title={<>Impact reporting</>}
        description={
          "Our impact reporting and storytelling tools help you understand your impact and improve stakeholder engagement."
        }
        imageUrl={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/Untitled-design-4.png"
        }
        colorWay={2}
      />
      {/*<LPFeatureBlock*/}
      {/*  subTitle={"The Problem"}*/}
      {/*  title={"Branded Merchandise is broken"}*/}
      {/*  fill={"red"}*/}
      {/*  description={*/}
      {/*    <ul>*/}
      {/*      <li>Low quality & wasteful</li>*/}
      {/*      <li>Limited choice</li>*/}
      {/*      <li>Lack of transparency</li>*/}
      {/*    </ul>*/}
      {/*  }*/}
      {/*  imageUrl={*/}
      {/*    "https://data.socialsupermarket.org/wp-content/uploads/2018/10/The-Social-Supermarket-Branded-Merch-2-landscape-scaled.jpg"*/}
      {/*  }*/}
      {/*/>*/}
      <LPEnquiryFormCTABlock
        onClick={() => setShowModal(true)}
        description={
          "Speak to one of our team and we would be pleased to get you set up or answer any questions you may have."
        }
      />

      <LPReviewsAndTrustBlock />
      <LPBrochureCTABlock
        subheading={"MAKE RIPPLES"}
        title={"Free 10-step checklist for buying better branded merchandise"}
        description={
          "Whether you’re new to the game or a veteran branded merch buyer, we promise you this scorecard will ensure you tick all the boxes and connect with your audience on a deeper level. Skip to the end for a BONUS product and supplier scorecard."
        }
        buttonLabel={"Download Checklist"}
        buttonTo={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/10-Step-Impact-checklist-Impact-scorecard.pdf"
        }
        imageSrc={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/Screenshot-2023-09-07-at-20.46.20.png"
        }
        leadCapture
        leadCaptureTitle={"Download Checklist"}
        leadCaptureDescription={"Please provide your details to download the Impact Checklist."}
        leadCaptureEventName={"DownloadImpactChecklist"}
        leadCaptureAnalyticsName={"download_impact_checklist"}
      />
      <LPCorporateQuotesBlock />
      <LPParallaxBlock
        imageSrc={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/The-Social-Supermarket-Branded-Merch-8-scaled-e1683648985381.jpg"
        }
      />
      <LPLeadMagnetModal
        config={{
          popupTitle: "Download our Brochure",
          popupDescription: "Please provide your details to download our brochure.",
          eventName: "DownloadMerchBrochure",
          analyticsEventName: "brochure_download_merch",
          fileUrl:
            "https://www.canva.com/design/DAF9mvvRXP0/HGcw3EPCuzZIO3du12qtNw/view?utm_content=DAF9mvvRXP0&utm_campaign=designshare&utm_medium=link&utm_source=editor",
          buttonTitle: "Download Brochure",
        }}
        show={showLeadCaptureModal}
        onClose={() => setShowLeadCaptureModal(false)}
      />
      <EnquiryFormModal
        toggle={setShowModal}
        show={showModal}
        source={"Inbound Enquiry"}
        name={"BrandedMerchEnquiryForm"}
      />
    </div>
  )
}

export default SustainableBrandedMerchandiseLP
